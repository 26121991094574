
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import Points from '../Points.vue';

export default defineComponent({
  props: {
    skill: {
      required: false,
      type: Object,
    },
    person: {
      required: true,
      type: Object,
    },
    linked: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Points,
  },
  setup(props) {
    const store = useStore();
    const avatar = computed(() => store.getters['getAvatarUrl'](props.person.avatar));

    const dims = computed(() => {
      return [
        {
          // @ts-ignore
          name: props.skill.dim1.name,
          // @ts-ignore
          value: props.person.dim1,
        },
        {
          // @ts-ignore
          name: props.skill.dim2.name,
          // @ts-ignore
          value: props.person.dim2,
        },
      ];
    });

    return {
      dims,
      avatar,
    };
  },
});
