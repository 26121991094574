
import { defineComponent } from 'vue';
import Person from './Person.vue';

export default defineComponent({
  props: {
    persons: {
      type: Array,
      required: true,
    },
    skill: {
      type: Object,
      default: false,
    },
    linked: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Person,
  },
});
