<style lang="scss">
.person-list {
  display: flex;
  flex-wrap: wrap;
  margin-top:30px;
}
</style>

<template>
  <div class="person-list">
    <Person :person="person" :key="index" v-for="(person, index) in persons" :skill="skill" :linked="linked" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Person from './Person.vue';

export default defineComponent({
  props: {
    persons: {
      type: Array,
      required: true,
    },
    skill: {
      type: Object,
      default: false,
    },
    linked: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Person,
  },
});
</script>
