<style lang="scss">
.person {
  position: relative;
  text-align: center;
  padding: 15px;
  margin-bottom: 10px;

  .dimcontainer {
    margin: 0 auto;
    margin-top: 10px;
  }

  .avatar {
    border-radius: 50%;
    display: inline-block;
    height: $avatar-size;
    width: $avatar-size;
    background-size: cover;
    background-position: center center;
    @media (max-width: 1400px) {
      width: 100px;
      height: 100px;
    }
    @media (max-width: 768px) {
    }
  }

  .skills {
    margin-top: 10px;
    .dim {
      display: inline-block;
      color: #fff;
      padding: 10px 15px;
      margin: 1px;
    }
  }
}
.linked {
  .link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .avatar {
    transition: all 0.2s ease;
  }
  &:hover {
    cursor: pointer;
    .avatar {
      transform: scale(0.9);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
  }
}
</style>

<template>
  <div class="person col-xl-2 col-lg-3 col-md-4" :class="{ linked }">
    <router-link class="link" v-if="linked" :to="{ name: 'Profil', params: { id: person._id } }"></router-link>
    <div class="avatar" :style="`background-image:url(${avatar})`"></div>
    <div class="name">
      {{ person.nameFirst }}<br />
      {{ person.nameLast }}
    </div>
    <Points :dims="dims" v-if="skill && person.dim1 && person.dim2" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import Points from '../Points.vue';

export default defineComponent({
  props: {
    skill: {
      required: false,
      type: Object,
    },
    person: {
      required: true,
      type: Object,
    },
    linked: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Points,
  },
  setup(props) {
    const store = useStore();
    const avatar = computed(() => store.getters['getAvatarUrl'](props.person.avatar));

    const dims = computed(() => {
      return [
        {
          // @ts-ignore
          name: props.skill.dim1.name,
          // @ts-ignore
          value: props.person.dim1,
        },
        {
          // @ts-ignore
          name: props.skill.dim2.name,
          // @ts-ignore
          value: props.person.dim2,
        },
      ];
    });

    return {
      dims,
      avatar,
    };
  },
});
</script>
