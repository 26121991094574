<style lang="scss">
#skilldetails {
  .headline-h1 {
    margin-bottom: 8px;
  }
  .info-txt {
    display: block;
    margin-bottom: 40px;
    &:before {
      content: '';
      display: block;
      width: 100px;
      height: 3px;
      background: #eee;
      margin: 10px 0;
    }
  }
  .show-all {
    margin: 60px auto;
    display: block;
    padding: 10px 15px;
    height: auto;
    background: $c-smd-lachs;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 90%;
    &:hover {
      background: $c-smd-blue;
    }
  }

  .skills-liste-overview {

    padding:40px;
    display: block;
    width: 100%;
    text-align:center;

    .skill-liste-single {
      padding: 15px;
      background: #f9f9f9;
      border: 1px solid #fff;
      display: inline-block;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
      transition: all 0.2s;
      cursor: pointer;
      @media (max-width: 600px) {
        display: block;
      }
      @media (max-width: 500px) {
        text-align: center;
      }
      .nr {
        font-family: 'HelveticaNeueLTPro-Bd';
        font-size: 150%;
        display: inline-block;
        @media (max-width: 500px) {
          display: block;
        }
      }
      .title {
        margin-left: 15px;
        position: relative;
        top: -2px;
        display: inline-block;
        @media (max-width: 500px) {
          display: block;
          margin-left: 0;
        }
      }
      &:hover,
      &.active {
        background: #d9dada;
       
      }
    }
  }
}
</style>

<template>
  <div id="skilldetails" v-if="skillinfo.name != ''">
    <h1 class="headline-h1">{{ skillinfo.name }}</h1>
    <div class="info-txt" v-if="role <= 10">Unsere Experten in diesem Skill</div>
    <PersonList :persons="persons" :skill="skillinfo" :linked="linked" />

    <w-button class="show-all" @click="allButtonClick" v-if="role >= 20 && !showAll">Alle Mitarbeiter zeigen</w-button>

    <DimLegend class="ma2" :skill="skillinfo" v-if="role >= 20" />
    <div class="skills-liste-overview">
      <div class="skill-liste-single lg4 md6 sm12" :class="{ active: skill['_id'] == currentSkill }" @click="goToSkill(skill['_id'])" :key="index" v-for="(skill, index) in skills">
        <div class="txt">
          <span class="nr">{{ skill.percent }}%</span>
          <span class="title">{{ skill.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import PersonList from '../../components/Person/PersonList.vue';
import DimLegend from '../../components/DimLegend.vue';

export default defineComponent({
  components: {
    PersonList,
    DimLegend,
  },
  setup() {
    const allPersons = ref([]);
    const store = useStore();
    const router = useRouter();
    const skillinfo = ref<SkillInfo>({ name: '', group: '', dim1: { name: '', color: '' }, dim2: { name: '', color: '' } });
    const currentSkill = ref(router.currentRoute.value.params.id);
    const role = store.getters['getUserRole'];
    const showAll = ref(true);

    const skills = ref([]);

    const load = () => {
      //showAll.value = false;
      store.dispatch('fetchCompententForSkill', currentSkill.value).then((res) => {
        allPersons.value = res;
      });

      store.dispatch('fetchSkillInfo', currentSkill.value).then((res: SkillInfo) => {
        skillinfo.value = res;
      });

      store.dispatch('fetchSkills').then((res) => {
        skills.value = res
          .map((skill: any) => {
            skill.percent = Math.ceil((skill.reached / skill.max) * 100);
            return skill;
          })
          .sort((a: any, b: any) => {
            if (a.percent > b.percent) return -1;
            if (a.percent < b.percent) return 1;

            return 0;
          });
      });
    };

    load();

    const goToSkill = (skill: string) => {
      router.push({ name: 'SkillDetails', params: { id: skill } });
      currentSkill.value = skill;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      load();
    };

    const allButtonClick = () => {
      showAll.value = true; //!showAll.value;
    };

    const persons = computed(() => {
      if (showAll.value || role <= 20) {
        return allPersons.value;
      } else {
        return allPersons.value.filter((person: any) => {
          return person.dim1 >= 2 && person.dim2 >= 2;
        });
      }
    });

    return {
      allButtonClick,
      showAll,
      currentSkill,
      persons,
      skills,
      goToSkill,
      skillinfo,
      role,
      linked: computed(() => role >= 20),
    };
  },
});
</script>
