<style lang="scss" scoped>
.dimension-all {
  margin-top:30px;
 
  padding:10px 0;
  justify-content:center;
  
  .dimension {
    color: #fff;
    text-align:center;
    margin:5px;
    padding:10px;
    font-size:100%;
    max-width:300px;
    display:inline-block;
   
    @media(max-width:600px) {
      max-width:100%;
      width:100%;
      display:block;
      
    }
    
  }
}

</style>

<template>
  <w-flex v-if="skill.name != ''" class="dimension-all">
    <div class="xs6 dimension dim1" :class="`${skill.dim1.color}--bg`">{{ skill.dim1.name }}</div>
    <div class="xs6 dimension dim2" :class="`${skill.dim2.color}--bg`">{{ skill.dim2.name }}</div>
  </w-flex>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    skill: {
      type: Object,
      required: true,
    },
    // bgcolors: {
    //   type: Array,
    //   required: true,
    // },
  },
});
</script>
