
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import PersonList from '../../components/Person/PersonList.vue';
import DimLegend from '../../components/DimLegend.vue';

export default defineComponent({
  components: {
    PersonList,
    DimLegend,
  },
  setup() {
    const allPersons = ref([]);
    const store = useStore();
    const router = useRouter();
    const skillinfo = ref<SkillInfo>({ name: '', group: '', dim1: { name: '', color: '' }, dim2: { name: '', color: '' } });
    const currentSkill = ref(router.currentRoute.value.params.id);
    const role = store.getters['getUserRole'];
    const showAll = ref(true);

    const skills = ref([]);

    const load = () => {
      //showAll.value = false;
      store.dispatch('fetchCompententForSkill', currentSkill.value).then((res) => {
        allPersons.value = res;
      });

      store.dispatch('fetchSkillInfo', currentSkill.value).then((res: SkillInfo) => {
        skillinfo.value = res;
      });

      store.dispatch('fetchSkills').then((res) => {
        skills.value = res
          .map((skill: any) => {
            skill.percent = Math.ceil((skill.reached / skill.max) * 100);
            return skill;
          })
          .sort((a: any, b: any) => {
            if (a.percent > b.percent) return -1;
            if (a.percent < b.percent) return 1;

            return 0;
          });
      });
    };

    load();

    const goToSkill = (skill: string) => {
      router.push({ name: 'SkillDetails', params: { id: skill } });
      currentSkill.value = skill;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      load();
    };

    const allButtonClick = () => {
      showAll.value = true; //!showAll.value;
    };

    const persons = computed(() => {
      if (showAll.value || role <= 20) {
        return allPersons.value;
      } else {
        return allPersons.value.filter((person: any) => {
          return person.dim1 >= 2 && person.dim2 >= 2;
        });
      }
    });

    return {
      allButtonClick,
      showAll,
      currentSkill,
      persons,
      skills,
      goToSkill,
      skillinfo,
      role,
      linked: computed(() => role >= 20),
    };
  },
});
